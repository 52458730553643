@import url('https://fonts.googleapis.com/css?family=Lato|Nunito+Sans&display=swap');

* {
  box-sizing: border-box;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 20px;
  color: #444;
  line-height: 1.5;
  text-align:center;
  margin: 40px auto;
  text-transform: lowercase;
  font-variant: small-caps;
}

main {
  padding-bottom: 100px;
}

nav,
footer {
  padding: 1% 0;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #424242;
  -webkit-box-shadow: 0px 0px 10px rgba(0,0,0,.8);
  -moz-box-shadow: 0px 0px 10px rgba(0,0,0,.8);
             box-shadow: 0px 0px 10px rgba(0,0,0,.8);
  z-index: 100;
  color: whitesmoke;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #424242;
  text-align: center;
  -webkit-box-shadow: 0 4px 8px -2px rgba(0,0,0,1);
	-moz-box-shadow: 0 4px 8px -2px rgba(0,0,0,1);
  box-shadow: 0 4px 8px -2px rgba(0,0,0,1);
}

.dashboard {
  font-family: 'Lato';
  font-size: 20px;
  margin-top: 0;
  background-color: #424242;
  color: whitesmoke;
  border-radius: 5px 5px 0 0; 
}


ul {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  /* background-color: #aaa; */
}

@media only screen and (max-width: 640px) {
  section {
    width: 95%;
  }
}
@media only screen and (min-width: 1025px) {
  section {
    max-width: 50%;
  }
}

section {
  margin: 0 auto;
  min-height: 200px;
  padding: 2% 0;
  padding-bottom: 10%;
  background-color: whitesmoke;
  border-radius: 5px;
  margin-top: 2.5%;
  box-shadow: 5px 5px 10px;
  padding-top: 0;
  position: relative;
}

section:nth-child(even) {
  background-color: #ddd;
}

.top {
  margin-top: 100px;
}

h1, h2, h3 {
    font-family: 'Lato', 'sans-serif';
    color: #424242;
    letter-spacing:0.1em;
    text-align:center;
    padding-left: 25%;
    padding-right: 25%;
    margin: 40px auto;
    text-transform: lowercase;
    font-variant: small-caps;
  text-shadow: 0px 1px .5px rgba(0,0,0,0.1),
             0px 2px 1.5px rgba(0,0,0,0.1),
             0px 3px 2.5px rgba(0,0,0,0.1);
  
}


Label {
  padding-right: 5px;
}

.button {
  display: block;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
}

.wordList {
  padding-left: 0;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.wordListItem {
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 70%;
  border: solid #424242 1px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px;
}
