@import url(https://fonts.googleapis.com/css?family=Lato|Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu);
h1 {
  font-size: 2em;
  margin-bottom: 0px;
}

a {
  text-decoration: none;
  color: #444;
}

li a:hover {
  background-color: #484848;
  
}

header {
  padding-top: 2%;
  padding-bottom: none;
}

nav a:hover {
  background-color: #484848;
 
  
}

h1:hover {
  background-color: whitesmoke;
}

nav a {
  border: none;
  padding: 10px 2%;
  text-align: center;
  width: 100%;
  color: whitesmoke;
  -webkit-transition: .4s background-color;
  transition: .4s background-color;
  font-weight: 700;
  cursor: pointer;
  font-size: 20px;
}


input[type=submit] {
	border: none;
	padding: 10px 2%;
	text-align: center;
	width: 100%;
	color: #fff;
	font-weight: 700;
    cursor: pointer;
    font-size: 20px;
    border-radius: 0px 0px 15px 15px;
}

#form-container {
    width: 66%;
    margin: 40px auto;
    box-shadow: 2.5px 2.5px 5px;
    border-radius: 15px;
  }



Button:hover {
    cursor: pointer;
}

Button {
    text-align:center;
    text-transform: uppercase;
    -webkit-font-feature-settings: "c2sc";
            font-feature-settings: "c2sc";
    font-variant: small-caps;
    height: 30px;
    width: 50%;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    font-size: 100%;
    background-color:  #424242;
    color: whitesmoke;
}
p {
    color: #424242;
    letter-spacing:0.1em;
    text-align:center;
    padding-left: 25%;
    padding-right: 25%;
    margin: 40px auto;
    text-transform: lowercase;
    line-height: 145%;
    font-size: 14pt;
    -webkit-font-feature-settings: "c2sc";
            font-feature-settings: "c2sc";
    font-variant: small-caps;
    }


* {
  box-sizing: border-box;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 20px;
  color: #444;
  line-height: 1.5;
  text-align:center;
  margin: 40px auto;
  text-transform: lowercase;
  -webkit-font-feature-settings: "c2sc";
          font-feature-settings: "c2sc";
  font-variant: small-caps;
}

main {
  padding-bottom: 100px;
}

nav,
footer {
  padding: 1% 0;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #424242;
  box-shadow: 0px 0px 10px rgba(0,0,0,.8);
  z-index: 100;
  color: whitesmoke;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #424242;
  text-align: center;
 box-shadow: 0 4px 8px -2px rgba(0,0,0,1);
}

.dashboard {
  font-family: 'Lato';
  font-size: 20px;
  margin-top: 0;
  background-color: #424242;
  color: whitesmoke;
  border-radius: 5px 5px 0 0; 
}


ul {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  /* background-color: #aaa; */
}

@media only screen and (max-width: 640px) {
  section {
    width: 95%;
  }
}
@media only screen and (min-width: 1025px) {
  section {
    max-width: 50%;
  }
}

section {
  margin: 0 auto;
  min-height: 200px;
  padding: 2% 0;
  padding-bottom: 10%;
  background-color: whitesmoke;
  border-radius: 5px;
  margin-top: 2.5%;
  box-shadow: 5px 5px 10px;
  padding-top: 0;
  position: relative;
}

section:nth-child(even) {
  background-color: #ddd;
}

.top {
  margin-top: 100px;
}

h1, h2, h3 {
    font-family: 'Lato', 'sans-serif';
    color: #424242;
    letter-spacing:0.1em;
    text-align:center;
    padding-left: 25%;
    padding-right: 25%;
    margin: 40px auto;
    text-transform: lowercase;
    -webkit-font-feature-settings: "c2sc";
            font-feature-settings: "c2sc";
    font-variant: small-caps;
  text-shadow: 0px 1px .5px rgba(0,0,0,0.1),
             0px 2px 1.5px rgba(0,0,0,0.1),
             0px 3px 2.5px rgba(0,0,0,0.1);
  
}


Label {
  padding-right: 5px;
}

.button {
  display: block;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
}

.wordList {
  padding-left: 0;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.wordListItem {
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 70%;
  border: solid #424242 1px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


:root {
  --font-size: calc(10px + 1vmin);
}

