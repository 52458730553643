h1 {
  font-size: 2em;
  margin-bottom: 0px;
}

a {
  text-decoration: none;
  color: #444;
}

li a:hover {
  background-color: #484848;
  
}

header {
  padding-top: 2%;
  padding-bottom: none;
}

nav a:hover {
  background-color: #484848;
 
  
}

h1:hover {
  background-color: whitesmoke;
}

nav a {
  border: none;
  padding: 10px 2%;
  text-align: center;
  width: 100%;
  color: whitesmoke;
  transition: .4s background-color;
  font-weight: 700;
  cursor: pointer;
  font-size: 20px;
}

