input[type=submit] {
	border: none;
	padding: 10px 2%;
	text-align: center;
	width: 100%;
	color: #fff;
	font-weight: 700;
    cursor: pointer;
    font-size: 20px;
    border-radius: 0px 0px 15px 15px;
}

#form-container {
    width: 66%;
    margin: 40px auto;
    box-shadow: 2.5px 2.5px 5px;
    border-radius: 15px;
  }

